<template>
  <div>
    <v-row justify="center" v-if="besuch">
      <v-col cols="12">
        <v-card
          :color="template.colors.blocks"
          :style="`color: ${template.colors.block_text};`"
          :light="!template.isDark(template.colors.blocks)"
          :dark="template.isDark(template.colors.blocks)"
          class="pb-0 rounded-xl"
        >
          <v-card
            class="rounded-t-xl rounded-b-0 px-4"
            :color="template.colors.inline_secondary_text"
            :style="`background-color: ${template.colors.secondary};`"
          >
            <v-row>
              <v-col cols="auto" align-self="center" class="pb-0">
                <v-icon size="70px" color="success">
                  mdi-ticket
                </v-icon>
              </v-col>
              <v-col class="pb-0">
                <v-row>
                  <v-col
                    cols="auto"
                    class="text-center"
                    align-self="center"
                    v-if="besuch.veranstaltung.id != 'ANDERE'"
                  >
                    <v-icon
                      x-large
                      :color="template.colors.block_text"
                      v-if="besuch.veranstaltung.art == 'Training'"
                    >
                      mdi-strategy
                    </v-icon>
                    <v-icon
                      x-large
                      v-if="besuch.veranstaltung.art == 'Wettkampf'"
                      :color="template.colors.block_text"
                    >
                      mdi-podium
                    </v-icon>
                    <v-icon
                      x-large
                      v-if="besuch.veranstaltung.art == 'Freundschaftsspiel'"
                      :color="template.colors.block_text"
                    >
                      mdi-soccer
                    </v-icon>
                    <v-icon
                      x-large
                      v-if="besuch.veranstaltung.art == 'Meisterschaftsspiel'"
                      :color="template.colors.block_text"
                    >
                      mdi-soccer-field
                    </v-icon>
                    <v-icon
                      x-large
                      v-if="besuch.veranstaltung.art == 'Versammlung'"
                      :color="template.colors.block_text"
                    >
                      mdi-calendar-star
                    </v-icon>
                  </v-col>
                  <v-col v-if="besuch.veranstaltung.id == 'ANDERE'">
                    <h3
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="font-weight-light"
                    >
                      Andere Veranstaltung
                    </h3>
                    <h5
                      v-if="!$vuetify.breakpoint.smAndUp"
                      class="font-weight-light"
                    >
                      Andere Veranstaltung
                    </h5>
                    <h3
                      v-if="!$vuetify.breakpoint.smAndUp"
                      class="font-weight-bold"
                    >
                      {{ besuch.verein.name }}
                    </h3>
                    <h2
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="font-weight-bold"
                    >
                      {{ besuch.verein.name }}
                    </h2>
                  </v-col>
                  <v-col v-if="besuch.veranstaltung.id != 'ANDERE'">
                    <h3
                      v-if="$vuetify.breakpoint.smAndUp"
                      class="font-weight-light"
                    >
                      {{ besuch.veranstaltung.art }}
                    </h3>
                    <h5
                      v-if="!$vuetify.breakpoint.smAndUp"
                      class="font-weight-light"
                    >
                      {{ besuch.veranstaltung.art }}
                    </h5>
                    <h2 v-if="$vuetify.breakpoint.smAndUp">
                      <span
                        class="font-weight-bold"
                        v-if="besuch.veranstaltung.art == 'Training'"
                      >
                        {{ besuch.veranstaltung.mannschaft }}
                      </span>
                      <span
                        class="font-weight-bold"
                        v-if="
                          besuch.veranstaltung.art == 'Wettkampf' ||
                          besuch.veranstaltung.art == 'Versammlung'
                        "
                      >
                        {{ besuch.veranstaltung.name }}
                      </span>
                      <span
                        class="font-weight-bold"
                        v-if="
                          besuch.veranstaltung.art == 'Freundschaftsspiel' ||
                          besuch.veranstaltung.art == 'Meisterschaftsspiel'
                        "
                      >
                        <small>
                          {{ besuch.veranstaltung.mannschaft }} -
                          {{ besuch.veranstaltung.gastmannschaft }}
                        </small>
                      </span>
                    </h2>
                    <h3 v-if="!$vuetify.breakpoint.smAndUp">
                      <span
                        class="font-weight-bold"
                        v-if="besuch.veranstaltung.art == 'Training'"
                      >
                        {{ besuch.veranstaltung.mannschaft }}
                      </span>
                      <span
                        class="font-weight-bold"
                        v-if="
                          besuch.veranstaltung.art == 'Wettkampf' ||
                          besuch.veranstaltung.art == 'Versammlung'
                        "
                      >
                        {{ besuch.veranstaltung.name }}
                      </span>
                      <span
                        class="font-weight-bold"
                        v-if="
                          besuch.veranstaltung.art == 'Freundschaftsspiel' ||
                          besuch.veranstaltung.art == 'Meisterschaftsspiel'
                        "
                      >
                        <small>
                          {{ besuch.veranstaltung.mannschaft }} -
                          {{ besuch.veranstaltung.gastmannschaft }}
                        </small>
                      </span>
                    </h3>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
          <v-row justify="center" class="mt-2 px-4">
            <v-col cols="12" class="text-center" align-self="center">
              <small class="text-uppercase">
                {{
                  besuch.weitere_personen.length > 0
                    ? besuch.aktivitaet + ' EINES HAUSHALTS'
                    : besuch.aktivitaet
                }}
              </small>
              <div v-if="$vuetify.breakpoint.smAndUp">
                <h2>{{ besuch.person.vorname }} {{ besuch.person.name }}</h2>
                <h3
                  v-for="(person, index) in besuch.weitere_personen"
                  :key="index"
                >
                  <div v-if="!person.vorname">{{ person }}</div>
                  <div v-if="person.vorname">
                    {{ person.vorname }} {{ person.nachname }}
                  </div>
                </h3>
              </div>
              <div v-if="!$vuetify.breakpoint.smAndUp">
                <h3>{{ besuch.person.vorname }} {{ besuch.person.name }}</h3>
                <h4
                  v-for="(person, index) in besuch.weitere_personen"
                  :key="index"
                >
                  <div v-if="!person.vorname">{{ person }}</div>
                  <div v-if="person.vorname">
                    {{ person.vorname }} {{ person.nachname }}
                  </div>
                </h4>
              </div>
            </v-col>
          </v-row>
          <v-row
            justify="center"
            class="px-4"
            v-if="(besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit))"
            v-touch="{
              left: () => {
                show_timer = !show_timer
                show_timer_transition = false
              },
              right: () => {
                show_timer = !show_timer
                show_timer_transition = true
              },
            }"
          >
            <v-col
              cols="12"
              class="text-center"
              @click="show_timer = !show_timer"
            >
              <v-row justify="center">
                <v-col cols="12">
                  <small class="text-uppercase">Eingecheckt seit</small>
                </v-col>
              </v-row>
              <v-slide-x-transition v-if="show_timer_transition" hide-on-leave>
                <v-row
                  justify="center"
                  v-if="
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit)) &&
                    show_timer
                  "
                >
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.hours) }}</h1>
                    <small>STD</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.minutes) }}</h1>
                    <small>MIN</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.seconds) }}</h1>
                    <small>SEK</small>
                  </v-col>
                </v-row>
              </v-slide-x-transition>
              <v-slide-x-reverse-transition
                v-if="!show_timer_transition"
                hide-on-leave
              >
                <v-row
                  justify="center"
                  v-if="
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit)) &&
                    show_timer
                  "
                >
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.hours) }}</h1>
                    <small>STD</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.minutes) }}</h1>
                    <small>MIN</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto" align-self="center">
                    <h1>{{ addNull(checkintimer.seconds) }}</h1>
                    <small>SEK</small>
                  </v-col>
                </v-row>
              </v-slide-x-reverse-transition>
              <v-slide-x-transition v-if="show_timer_transition" hide-on-leave>
                <v-row
                  justify="center"
                  v-if="
                    !show_timer &&
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit))
                  "
                >
                  <v-col cols="auto">
                    <b>{{ parseDate1(besuch.zutritt.datum) }}</b>
                    <br />
                    <small>TAG</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto">
                    <b>{{ besuch.zutritt.uhrzeit }} UHR</b>
                    <br />
                    <small>UHRZEIT</small>
                  </v-col>
                </v-row>
              </v-slide-x-transition>
              <v-slide-x-reverse-transition
                v-if="!show_timer_transition"
                hide-on-leave
              >
                <v-row
                  justify="center"
                  v-if="
                    !show_timer &&
                    (besuch.verlassen.datum > timer.datum ||
                      (besuch.verlassen.datum == timer.datum &&
                        besuch.verlassen.uhrzeit > timer.uhrzeit))
                  "
                >
                  <v-col cols="auto">
                    <b>{{ parseDate1(besuch.zutritt.datum) }}</b>
                    <br />
                    <small>TAG</small>
                  </v-col>
                  <v-divider vertical></v-divider>
                  <v-col cols="auto">
                    <b>{{ besuch.zutritt.uhrzeit }} UHR</b>
                    <br />
                    <small>UHRZEIT</small>
                  </v-col>
                </v-row>
              </v-slide-x-reverse-transition>
            </v-col>
            <v-row justify="center">
              <v-col cols="auto" class="pr-0">
                <v-icon small v-if="show_timer">mdi-circle</v-icon>
                <v-icon small v-if="!show_timer">mdi-circle-outline</v-icon>
              </v-col>
              <v-col cols="auto">
                <v-icon small v-if="!show_timer">mdi-circle</v-icon>
                <v-icon small v-if="show_timer">mdi-circle-outline</v-icon>
              </v-col>
            </v-row>
          </v-row>
          <v-row
            justify="center"
            class="pa-0"
            v-if="
              besuch.verlassen.datum > timer.datum ||
              (besuch.verlassen.datum == timer.datum &&
                besuch.verlassen.uhrzeit > timer.uhrzeit)
            "
          >
            <v-col cols="12" class="text-center">
              <v-btn
                icon
                :color="template.colors.primary"
                v-if="start_checkout"
                @click="start_checkout = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn
                :color="
                  start_checkout
                    ? template.colors.success
                    : template.colors.primary
                "
                :style="`color: ${
                  start_checkout
                    ? template.colors.success_text
                    : template.colors.inline_primary_text
                };`"
                rounded
                large
                @click="start_checkout ? checkout() : (start_checkout = true)"
                :loading="checking_out"
              >
                <h3>
                  {{ start_checkout ? 'Bestätigen' : 'Jetzt auschecken' }}
                </h3>
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="mt-2 px-4">
            <v-col
              cols="12"
              class="text-center"
              v-if="
                besuch.verlassen.datum > timer.datum ||
                (besuch.verlassen.datum == timer.datum &&
                  besuch.verlassen.uhrzeit > timer.uhrzeit)
              "
            >
              <span>
                Du bist automatisch bis
                <span class="font-weight-bold">
                  {{
                    besuch.verlassen.datum > timer.datum
                      ? ' zum ' + parseDate1(besuch.verlassen.datum) + ' um '
                      : ''
                  }}{{ besuch.verlassen.uhrzeit }} Uhr
                </span>
                eingecheckt.
              </span>
            </v-col>
            <v-col
              cols="12"
              class="text-center"
              v-if="
                (besuch.verlassen.datum <= timer.datum &&
                  besuch.verlassen.uhrzeit <= timer.uhrzeit)
              "
            >
              <h3>Seit {{ besuch.verlassen.uhrzeit }} Uhr ausgecheckt.</h3>
            </v-col>
          </v-row>
        </v-card>
        <v-row justify="center" class="mt-5">
          <v-col
            cols="12"
            class="text-center"
            v-if="besuch.verein.hygienekonzept"
          >
            <h1>
              {{
                besuch.verein.hygienekonzept.custom_header.activated
                  ? besuch.verein.hygienekonzept.custom_header.title
                  : 'HYGIENE REGELN'
              }}
            </h1>
            <h3 v-if="!besuch.verein.hygienekonzept.custom_header.activated">
              {{ besuch.verein.name }}
            </h3>
            <h4 v-if="besuch.verein.hygienekonzept.custom_header.activated">
              {{ besuch.verein.hygienekonzept.custom_header.text }}
            </h4>
            <v-btn
              block
              rounded
              class="my-2"
              :color="template.colors.primary"
              :href="besuch.verein.hygienekonzept.custom_header.link"
              target="_blank"
              v-if="besuch.verein.hygienekonzept.custom_header.link"
            >
              {{
                besuch.verein.hygienekonzept.custom_header.linkname
                  ? besuch.verein.hygienekonzept.custom_header.linkname
                  : 'Website öffnen'
              }}
              <v-icon class="ml-2">mdi-open-in-new</v-icon>
            </v-btn>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
              v-if="besuch.verein.hygienekonzept.testpflicht"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/icon_3g.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>
                    {{
                      besuch.verein.hygienekonzept.ggg_regel
                        ? besuch.verein.hygienekonzept.ggg_regel.titel
                        : '3G-Regel'
                    }}
                  </h3>
                  <span>
                    Es ist zwingend ein negativer COVID-19 Test (nicht älter als
                    48h), ein Nachweis über eine vollständige COVID-19 Impfung
                    oder ein Nachweis über eine Genesung von COVID-19 (nicht
                    älter als 6 Monate).
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
              v-if="besuch.verein.hygienekonzept.abstand.activated"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/abstand.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>{{ besuch.verein.hygienekonzept.abstand.title }}</h3>
                  <span>
                    {{ besuch.verein.hygienekonzept.abstand.text }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
              v-if="besuch.verein.hygienekonzept.bereiche.activated"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/bereiche.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>{{ besuch.verein.hygienekonzept.bereiche.title }}</h3>
                  <span>
                    {{ besuch.verein.hygienekonzept.bereiche.text }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
              v-if="besuch.verein.hygienekonzept.desinfektion.activated"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/haende.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>
                    {{ besuch.verein.hygienekonzept.desinfektion.title }}
                  </h3>
                  <span>
                    {{ besuch.verein.hygienekonzept.desinfektion.text }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
              v-if="besuch.verein.hygienekonzept.kontakte.activated"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/no_handshake.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>
                    {{ besuch.verein.hygienekonzept.kontakte.title }}
                  </h3>
                  <span>
                    {{ besuch.verein.hygienekonzept.kontakte.text }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
              v-if="besuch.verein.hygienekonzept.maske.activated"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/maske.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>
                    {{ besuch.verein.hygienekonzept.maske.title }}
                  </h3>
                  <span>
                    {{ besuch.verein.hygienekonzept.maske.text }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
              v-if="besuch.verein.hygienekonzept.symptome.activated"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/healthy.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>
                    {{ besuch.verein.hygienekonzept.symptome.title }}
                  </h3>
                  <span>
                    {{ besuch.verein.hygienekonzept.symptome.text }}
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            class="text-center"
            v-if="!besuch.verein.hygienekonzept"
          >
            <h1>HYGIENE REGELN</h1>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/maske.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>MUND- & NASENSCHUTZ</h3>
                  <span>
                    Trage auf dem Gelände und vor allem in geschlossenen Räumen
                    stets einen Mund- & Nasenschutz
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/abstand.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>HALTE ABSTAND</h3>
                  <span>
                    Achte auf den Mindestabstand - auch im Zuschauerbereich
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl my-4"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center" align-self="center">
                  <v-img src="/img/covid-icons/haende.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>HÄNDE DESINFIZIEREN</h3>
                  <span>
                    Desinfiziere deine Hände beim Betreten & Verlassen des
                    Geländes oder wasche dir für min. 20 Sekunden deine Hände
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl mt-3 mb-4"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center">
                  <v-img src="/img/covid-icons/healthy.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>FÜHLST DU DICH FIT?</h3>
                  <span>
                    Bitte bleibe zu Hause, wenn du dich krank fühlst.
                  </span>
                </v-col>
              </v-row>
            </v-card>
            <v-card
              :color="template.colors.blocks"
              :style="`color: ${template.colors.block_text};`"
              class="rounded-xl my-4"
            >
              <v-row class="pa-4">
                <v-col cols="4" md="3" class="text-center" align-self="center">
                  <v-img src="/img/covid-icons/no_handshake.png"></v-img>
                </v-col>
                <v-col cols="8" md="9" class="text-left" align-self="center">
                  <h3>VERMEIDE KONTAKT</h3>
                  <span>
                    Verzichte auf übliche Begrüßungsrituale und Körperkontakt zu
                    anderen Personen
                  </span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import router from '../../routes'
//import firebase from '../../firebaseConfig'

export default {
  name: 'Start',
  data() {
    return {
      besuche: [],
      besuch: '',
      vereine: [],
      date: '',
      currenttime: '',
      dialog: false,
      datum: '',
      tabs: 1,
      checking_out: false,
      start_checkout: false,
      show_timer: true,
      checkintimer: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      show_timer_transition: false,
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      template: 'template',
      timer: 'timer',
    }),
  },
  mounted() {
    var currentdate = new Date()
    var datestring =
      currentdate.getFullYear() +
      '-' +
      (currentdate.getMonth() + 1 < 10
        ? '0' + (currentdate.getMonth() + 1)
        : currentdate.getMonth() + 1) +
      '-' +
      (currentdate.getDate() < 10
        ? '0' + currentdate.getDate()
        : currentdate.getDate())
    var timestring =
      (currentdate.getHours() < 10
        ? '0' + currentdate.getHours()
        : currentdate.getHours()) +
      ':' +
      (currentdate.getMinutes() < 10
        ? '0' + currentdate.getMinutes()
        : currentdate.getMinutes())
    if (this.$route.params.ticket) {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.uid)
        .collection('Besuche')
        .doc(this.$route.params.ticket)
        .get()
        .then((doc) => {
          currentdate = new Date()
          datestring =
            currentdate.getFullYear() +
            '-' +
            (currentdate.getMonth() + 1 < 10
              ? '0' + (currentdate.getMonth() + 1)
              : currentdate.getMonth() + 1) +
            '-' +
            (currentdate.getDate() < 10
              ? '0' + currentdate.getDate()
              : currentdate.getDate())
          timestring =
            (currentdate.getHours() < 10
              ? '0' + currentdate.getHours()
              : currentdate.getHours()) +
            ':' +
            (currentdate.getMinutes() < 10
              ? '0' + currentdate.getMinutes()
              : currentdate.getMinutes())

          var besuch = doc.data()
          console.log(besuch)
          if (
            (besuch.verlassen.datum == datestring &&
              besuch.verlassen.uhrzeit > timestring) ||
            besuch.verlassen.datum > datestring
          ) {
            this.besuch = besuch
            this.besuch.id = doc.id
            this.checkin_timer()
          } else {
            router.push('/user/tickets')
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      router.push('/user/tickets')
    }
  },
  methods: {
    setTime() {
      setTimeout(() => {
        var today = new Date()
        this.datum =
          today.getFullYear() +
          '-' +
          this.addNull(today.getMonth() + 1) +
          '-' +
          this.addNull(today.getDate())
        this.currenttime =
          this.addNull(parseInt(today.getHours())) +
          ':' +
          this.addNull(parseInt(today.getMinutes()))
        this.check_besuche()
        this.setTime()
      }, 60000)
    },
    check_besuche() {
      firebase
        .firestore()
        .collection('User')
        .doc(this.user.uid)
        .collection('Besuche')
        .where('veranstaltung.datum', '==', this.datum)
        .orderBy('zutritt', 'desc')
        .get()
        .then((snap) => {
          this.besuche = []
          snap.forEach((doc) => {
            if (
              !this.compare_time(this.currenttime, doc.data().verlassen) &&
              !doc.data().checkout
            ) {
              this.besuche.push(doc.data())
              var index = this.besuche.length - 1
              this.besuche[index].id = doc.id
            }
          })
        })
    },
    checkin_timer() {
      var start = this.besuch.zutritt.timestamp
      var end = Date.now()
      var elapsed = end - start
      var difference = new Date(elapsed)
      this.checkintimer = {
        hours: difference.getUTCHours(),
        minutes: difference.getUTCMinutes(),
        seconds: difference.getUTCSeconds(),
      }
      setTimeout(() => {
        this.checkin_timer()
      }, 1000)
    },
    addNull(num) {
      if (num >= 10) {
        return num
      }
      return '0' + num
    },
    compare_time(t1, t2) {
      var zeit1 = t1.split(':')
      var zeit2 = t2.split(':')
      if (zeit1[0] > zeit2[0]) {
        return true
      } else {
        if (zeit1[0] == zeit2[0] && zeit1[1] > zeit2[1]) {
          return true
        } else {
          return false
        }
      }
    },
    checkout() {
      this.checking_out = true
      var event = this.besuch
      if (event.verlassen.timestamp > Date.now()) {
        var verlassen = new Zeit(
          this.timer.datum,
          this.timer.uhrzeit_format_sec,
        )
        firebase
          .firestore()
          .collection('User')
          .doc(event.anwesenheit.verein)
          .collection('Anwesenheit')
          .doc(event.anwesenheit.id)
          .update({
            verlassen: {
              datum: verlassen.getDatum(),
              uhrzeit: verlassen.getUhrzeit(),
              timestamp: verlassen.getTimestamp(),
            },
          })
          .then((snap) => {
            firebase
              .firestore()
              .collection('User')
              .doc(this.user.uid)
              .collection('Besuche')
              .doc(event.id)
              .update({
                verlassen: {
                  datum: verlassen.getDatum(),
                  uhrzeit: verlassen.getUhrzeit(),
                  timestamp: verlassen.getTimestamp(),
                },
                checkout: true,
              })
              .then(() => {
                this.checking_out = ''
                router.push('/user/besuche')
              })
          })
      } else {
        this.checking_out = false
      }
    },
    parseDate(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      var weekdays = [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ]
      var weekday = datum_obj.getDay()
      return (
        weekdays[weekday] + ', ' + datum[2] + '.' + datum[1] + '.' + datum[0]
      )
    },
    parseDate1(date) {
      var datum = date.split('-')
      var datum_obj = new Date(date)
      return datum[2] + '.' + datum[1] + '.' + datum[0]
    },
  },
}
</script>
